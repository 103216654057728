import ReactGA from 'react-ga';

export const Gallery = (props) => {
  
  function trackClick (currentaction) {
    ReactGA.initialize("UA-198578766-2");
    ReactGA.event({
      category: 'Click',
      action: currentaction
    });
  }

  function trackMouseEnter(currentaction){
    ReactGA.initialize("UA-198578766-2");
    ReactGA.event({
      category: 'MouseOver',
      action: currentaction
    });
  }

  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Previous Projects</h2>
          <p>
            Below are just some of the projects we've created based on the ideas of our consumers.
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <div className='portfolio-item' >
                <div className='hover-bg'>
                  {' '} 
                  <a
                    href='img/portfolio/Cedar_Refurb_1000_750.jpg'
                    title='Refurbished Patio Set'
                    data-lightbox-gallery='gallery1'
                    onClick={() => trackClick('Refurbished Patio Set')}
                    onMouseEnter={() => trackMouseEnter('Refurbished Patio Set')}

                  > 
                    <div className='hover-text'                       
                    >
                      <h4>SOLD ($600)</h4>
                      <p>This refurbish Patio Set utilized existing metal frame and local cedar.</p>
                    </div>
                    <img
                      src='img/portfolio/Cedar_Refurb_1000_750.jpg'
                      className='img-responsive'
                      alt='Refurbished Patio Set'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <div className='portfolio-item'>
                <div className='hover-bg' >
                  {' '}
                  <a
                    href='img/portfolio/4Quad_Table_1000x750.jpg'
                    title='10 person Patio Table'
                    data-lightbox-gallery='gallery1'
                    onClick={() => trackClick('10 person Patio Table')}
                    onMouseEnter={() => trackMouseEnter('10 person Patio Table')}
                  >
                    <div className='hover-text'>
                      <h4>Invaluable</h4>
                      <p>This personal project utilized a chinaberry tree fallen in this exact spot. This table (and tree) will continue to live on where it has for 50y. </p>
                    </div>
                    <img
                      src='img/portfolio/4Quad_Table_1000x750.jpg'
                      className='img-responsive'
                      alt='10 person Patio Table'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/Live_Edge_Cedar_1000_750.jpg'
                    title='Cedar Coffee Table'
                    data-lightbox-gallery='gallery1'
                    onClick={() => trackClick('Cedar Coffee Table')}
                    onMouseEnter={() => trackMouseEnter('Cedar Coffee Table')}
                  >
                    <div className='hover-text' >
                      <h4>SOLD ($500)</h4>
                      <p>This live edge cedar table came from a fallen tree in Plano, TX.</p>
                    </div>
                    <img
                      src='img/portfolio/Live_Edge_Cedar_1000_750.jpg'
                      className='img-responsive'
                      alt='Cedar Coffee Table'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/White_Cedar_Table_1000_750.jpg'
                    title='White Cedar Table'
                    data-lightbox-gallery='gallery1'
                    onClick={() => trackClick('White Cedar Table')}
                    onMouseEnter={() => trackMouseEnter('White Cedar Table')}
                  >
                    <div className='hover-text'>
                      <h4>SOLD ($2200)</h4>
                      <p>This cedar top table and bar chairs was custom sized for this non-standard breakfast nook.</p>
                    </div>
                    <img
                      src='img/portfolio/White_Cedar_Table_1000_750.jpg'
                      className='img-responsive'
                      alt='White Cedar Table'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/Wallis_footboard_1000_750.jpg'
                    title='Wallis Footboard'
                    data-lightbox-gallery='gallery1'
                    onClick={() => trackClick('Wallis Footboard')}
                    onMouseEnter={() => trackMouseEnter('Wallis Footboard')}
                  >
                    <div className='hover-text'>
                      <h4>SOLD ($50)</h4>
                      <p>A beautifully stained and finished footboard for new baby Wallis.</p>
                    </div>
                    <img
                      src='img/portfolio/Wallis_footboard_1000_750.jpg'
                      className='img-responsive'
                      alt='Wallis Footboard'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/Kid_Car_Cover_1000_750.jpg'
                    title='Kid Car Cover'
                    data-lightbox-gallery='gallery1'
                    onClick={() => trackClick('Kid Car Cover')}
                    onMouseEnter={() => trackMouseEnter('Kid Car Cover')}
                  >
                    <div className='hover-text'>
                      <h4>SOLD ($500)</h4>
                      <p>A custom car cover with an aluminum cover on stained cedar for the kid's cars and toys in the backyard.</p>
                    </div>
                    <img
                      src='img/portfolio/Kid_Car_Cover_1000_750.jpg'
                      className='img-responsive'
                      alt='Kid Car Cover'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            {/* <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/03-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Lorem Ipsum</h4>
                    </div>
                    <img
                      src='img/portfolio/03-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/04-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Lorem Ipsum</h4>
                    </div>
                    <img
                      src='img/portfolio/04-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/05-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Adipiscing Elit</h4>
                    </div>
                    <img
                      src='img/portfolio/05-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/06-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Dolor Sit</h4>
                    </div>
                    <img
                      src='img/portfolio/06-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/07-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Dolor Sit</h4>
                    </div>
                    <img
                      src='img/portfolio/07-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/08-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Lorem Ipsum</h4>
                    </div>
                    <img
                      src='img/portfolio/08-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div> */}
            {/* <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/09-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Adipiscing Elit</h4>
                    </div>
                    <img
                      src='img/portfolio/09-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
